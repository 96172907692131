<template>
  <div class="bg">

    <div class="row justify-content-center">
      <!-- kiri -->
      <div class="col-8 pr-1">
        <!-- 1 -->
        <div class="row-8">
          <profile-summary :view-only="true" />
        </div>
        <!-- 2 -->
        <div
          v-if="roles.isExecutive || roles.isUser || roles.isTopManagement"
          class="row-8 mt-2"
        >
          <!-- :img-src="require('@/assets/images/background/Vector 25.png')"
            img-bottom
            img-alt="card img"
            class="mb-1"
            style="
                display: flex;
                border-radius: 20px;
                height: 445px;
                overflow-y: auto;
              " -->
          <b-card
            v-if="isTrend"
          >
            <b-card-header class="saj-header d-flex justify-content-start pb-0">
              {{ $t('Previous Trend') }}
              <b-col
                class="d-flex justify-content-end"
              >
                <Datepicker
                  id="input-id"
                  :value="defaultDate"
                  :format="DatePickerFormat"
                  minimum-view="year"
                  name="datepicker"
                  class="saj-title"
                />
              </b-col>

              <!-- <flatpickr :config="{ dateFormat: 'Y', noCalendar: true,}" /> -->
            </b-card-header>
            <div
              class="container"
              style="position: relative;
                     text-align: center
                     "
            >
              <p
                style="font-style: italic;
                       position: absolute;
                       top: 50%;
                       left: 50%;
                       transform: translate(-50%, -50%);
                       width: 100%;
                       height: 2rem;
                       text-align: center;
                       background-color: #ffffffeb;
                       align-items: center;
                       display: flex;
                       justify-content: center
                      "
              >
                Feature coming soon
              </p>
              <img
                src="@/assets/images/pages/coming-soon.svg"
                alt=""
              >
            </div>

          </b-card>
        </div>
        <!-- 3 -->
        <div
          v-if="roles.isExecutive || roles.isUser || roles.isTopManagement"
          class="row-8 mt-2"
        >
          <b-card
            no-body
            class="p-1"
            style="border-radius: 20px; overflow-y: auto"
          >
            <b-card-header class="d-flex justify-content-start saj-header">
              {{ $t("Calendar") }}
            </b-card-header>
            <calendarDisplay />
            <!-- <div class="d-flex justify-content-start">
              <b-button
                class="btn m-1 eventtext"
                variant="info"
                style="
                  font-family: Poppins;
                  font-weight: 400;
                  font-style: normal;
                  font-size: 20px;
                  color: white;
                "
                @click="showCalendarModal()"
              >
                Cipta Mesyuarat
              </b-button>
            </div> -->
          </b-card>
        </div>
        <!-- 4 -->
        <!-- <div
          v-if="roles.isExecutive || roles.isUser || roles.isTopManagement"
          class="row-8 mt-2 "
        > -->
        <!-- <b-card
            no-body
            class="p-1"
            style="border-radius: 20px; overflow-y: auto"
          > -->
        <!-- <b-card-header class="d-flex justify-content-start saj-header">

              {{ $t("Project Management") }}

            </b-card-header>
            <management-table-non-exec v-if="roles.isUser" />
            <div
              v-if="roles.isExecutive || roles.isTopManagement"
              style=""
            >
              <b-button
                v-b-modal.create-title
                class="btn m-1 saj-button"
                variant="info"
                style=""
                :hidden="title !== null && title !== ''"
              >
                {{ $t("Add Project Title") }}
              </b-button>
            </div> -->
        <!--/ metting header -->
        <!-- header -->
        <!-- <b-container
                  class=""
                  style="font-family: Poppins;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: black;
                  overflow-y: auto;"
                > -->
        <!-- <div
              v-if="roles.isExecutive || roles.isTopManagement"
              class="mt-1"
            >
              <b-row class="">
                <b-col cols="7">
                  <span
                    class="ml-1 pt-1 saj-title"
                    style=""
                  >
                    {{ title }}
                  </span>
                </b-col>
                <b-col
                  cols="5"
                  class="justify-content-end"
                >
                  <b-modal
                    id="edit-title"
                    :hide-footer="true"
                    :hide-header="true"
                    :centered="true"
                  >
                    <edit-title
                      :id="title_id"
                      :get-title="title"
                      @close="$bvModal.hide('edit-title')"
                      @update-title="updateProjectTitle"
                    />
                  </b-modal>
                  <div class="d-flex justify-content-end mr-1">
                    <b-button
                      v-if="title !== null"
                      v-b-modal.edit-title
                      class="m-0 mr-1 ml-2 saj-button"
                      variant="primary"
                      style=""
                    >
                      {{ $t("Edit") }}
                    </b-button>
                    <b-button
                      v-if="title !== null"
                      v-b-modal.delete-title
                      class="saj-button"
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                    >

                      {{ $t("Delete") }}
                    </b-button>
                  </div>

                  <b-modal
                    id="delete-title"
                    :hide-footer="true"
                    :hide-header="true"
                    :centered="true"
                  >
                    <delete-project
                      :id="title_id"
                      @close="$bvModal.hide('delete-title')"
                      @delete-project="deleteTitle"
                    />
                  </b-modal>
                </b-col>
              </b-row>

              <management-table
                v-if="title_id !== null"
                class="p-1"
                :project-i-d="title_id"
              />

            </div>
          </b-card> -->
        <!-- </div> -->
        <!-- 5 -->
        <!-- <div v-if="roles.isHOD">
          <div class="row-8 mt-2">
            <b-card
              style="border-radius: 20px; overflow-y: auto"
              class="mb-2"
            >
              <b-card-header class="d-flex justify-content-start saj-header">
                {{ $t("Report") }}
              </b-card-header> -->
        <!-- <b-card-body>report
              </b-card-body> -->
        <!-- </b-card>
          </div>
        </div> -->
      </div>

      <!-- kanan -->
      <div
        class="col-4"
      >
        <!-- 1 -->
        <div
          v-if="roles.isUser || roles.isExecutive || roles.isTopManagement"
          class="row-4"
        >
          <b-card
            class="mb-2"
            no-body
            style="border-radius: 20px; overflow-y: auto"
          >
            <div class="saj-header d-flex justify-content-between align-items-center">
              <b-card-header class="">

                {{ $t('My Score') }}
              </b-card-header>
              <div style="margin-right: 10px;">
                <Datepicker
                  :value="currentYear"
                  :format="customFormatter"
                  minimum-view="year"
                  name="datepicker"
                  class="show-year-dashboard"
                /></div>
            </div>

            <b-card-body>
              <div class="d-block justify-content-space-between">
                <div class="text-center justify-content-center">
                  <b-row class="">
                    <b-col class="text-center">
                      <span
                        class="d-flex justify-content-center font-weight-bold"
                        style="font-size: 3rem !important; color: #000000"
                      >
                        <!-- {{ scoreYearSelected }} -->
                        <!-- {{ getYearScore(scoreYearSelected) }} -->
                        <!-- {{ score }} -->
                        <div v-if="scoreValue !== null">
                          {{ score }}
                        </div>
                        <div v-else>
                          <span
                            class="d-flex justify-content-center font-weight-bold"
                            style="font-size: 3rem !important; color: #000000"
                          >0</span>
                        </div>
                      </span>
                      <div>
                        <b-card-text
                          class="saj-header"
                          style="
                              color: #000000;
                            "
                        >
                          {{ $t("Score") }}
                        </b-card-text>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row
                    class="mt-1 justify-content-center saj-text mb-2"
                    style=""
                  >
                    <!-- tak masuk ke {{ scoreValue.shr_approve }} -->
                    <div v-if="scoreValue !== null">
                      <div v-if="scoreValue.shr_approve === 1">
                        <div>{{ $t("Final Evaluation") }} : {{ scoreValue.final_evaluation }}</div>
                      </div>
                      <div v-if="adminApprove === 0">
                        <!-- <div>{{ $t("Performance Grade") }} : {{ perfGrade }}</div> -->
                        {{ $t("Not Evaluated") }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="justify-content-center saj-text">
                        {{ $t("Not Evaluated") }}</div>
                    </div>
                  </b-row>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <!-- 2 -->
        <div
          v-if="roles.isExecutive || roles.isUser || roles.isTopManagement"
          class="row-4"
        >
          <b-card
            class="mb-2"
            no-body
            style="border-radius: 20px; overflow-y: auto"
          >
            <b-card-header class="d-flex justify-content-start saj-header">
              {{ $t("Action Needed") }} {{ scoreYearSelected }}
            </b-card-header>

            <!-- apex chart -->
            <b-card-body>
              <vue-apex-charts
                type="radialBar"
                height="auto"
                :options="goalOverviewRadialBar.chartOptions"
                :series="goalOverviewRadialBar.series"
                class=""
              />
              <div
                v-if="percentage === 100"
                class="container"
              />
              <div
                v-else
                class="container mt-1"
              >
                <h4 class="saj-title">
                  {{ $t("Incomplete tasks") }}:
                </h4>
                <div
                  v-for="(item, index) in items"
                  :key="index"
                  class="saj-text"
                >
                  <div v-if="item.name === 'Personal' || item.name === 'Organization' || item.name === 'Education' ">
                    <router-link
                      :to="{name: 'team-profile', params:{tabIdx: item.idxTab}}"
                    >
                      <div
                        class="pointer"
                      >
                        {{ $t(item.name) }}
                      </div>
                    </router-link>
                  </div>
                  <div v-else>
                    <router-link
                      :to="{ name: 'my-performance', params: { id: item.perfId, tabIdx: item.idxTab, status: 'Reviewing' } }"
                    >
                      {{ $t(item.name) }}
                    </router-link>
                  </div>

                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <div
          v-if="roles.isExecutive || roles.isTopManagement"
          class="row-4"
        >
          <b-card
            class="mb-2"
            no-body
            style="border-radius: 20px; overflow-y: auto"
          >
            <b-card-header class="d-flex justify-content-start saj-header">
              {{ $t("Top Performer") }} {{ scoreYearSelected }}
            </b-card-header>
            <b-card-body>
              <topPerformer
                :key="refreshTop"
                class="saj-text"
                :year="scoreYearSelected.toString()"
              />
            </b-card-body>
          </b-card>
        </div>
        <!-- sini -->

      </div>

      <!-- <template v-if="roles.isExecutive || roles.isHOD || roles.isTopManagement">
        <b-modal
          id="create-title"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <CreateTitle
            @close="$bvModal.hide('create-title')"
            @reload-create="createProjectActivity"
          />
        </b-modal>

      </template> -->
    </div>
  </div>
</template>
  <style scoped>
  .satu {
    display: flex;
  }
  </style>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  // BCardTitle,
  BCardText,
  // BDropdown,
  // BDropdownItem,
  BCardBody,
  // BFormSelect,
  // BBadge,
  // BProgress,
  // BPagination,
  // BTable,
  // BButton,
  // BTableSimple,
  // BModal,
} from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import { $themeColors } from "@themeConfig"
// import axios from "axios"
// import moment from "moment"
// import managementTable from "@/component/projectManagement/ManagementTable.vue"
// import managementTableNonExec from "@/pages/non-exec/ManagementTable.vue"
import topPerformer from "@/component/top-performer.vue"
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// import { codeBasic } from './code'
// import addMember from "@/component/projectManagement/addMember.vue"
// import editMember from "@/component/projectManagement/EditMember.vue"
// import editTitle from "@/component/projectManagement/EditTitle.vue"
// import deleteProject from "@/component/projectManagement/Delete_Project.vue"
// import CreateTitle from "@/component/projectManagement/CreateTitle.vue"
// import modalC from "@/component/calendar.vue"
import calendarDisplay from "@/component/calendar-display.vue"
import moment from "moment"
import { mapGetters } from 'vuex'
// import flatpickr from 'vue-flatpickr-component'
import Datepicker from 'vuejs-datepicker'
import ProfileSummary from '@/component/29_profile_summary.vue'

export default {
  components: {
    // vuejsDatepicker,
    Datepicker,
    // modalC,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    // BFormSelect,
    // managementTable,
    // managementTableNonExec,
    // BButton,
    VueApexCharts,
    // addMember,
    // editMember,
    // editTitle,
    // deleteProject,
    // CreateTitle,
    topPerformer,
    // modalC,
    calendarDisplay,
    // BModal,
    // flatpickr,
    ProfileSummary,
  },
  data() {
    return {
      adminApprove: null,
      score: null,
      finalEva: null,
      perfGrade: null,
      isTrend: false,
      currentYear: '',
      DatePickerFormat: 'yyyy',
      percentage: null,
      profileImage: null,
      subsidiary: "",
      approved_at: "",
      title: null,
      title_id: null,
      scoreValue: 0,
      name: "",
      role: "",
      email: "",
      position: "",
      mobile_number: "",
      employee_number: "",
      department_id: "",
      supervisor_id: "",
      hire_date: "",
      overall_total: 0,
      overall_total_manager: "",
      managerscore: "5.6",
      date: "25 MAY 2021",
      goal_overview: {},
      items: [],
      goalOverviewRadialBar: {
        series: [],
        chartOptions: {
          // responsive: [{
          //   breakpoint: 400,
          // options: {
          chart: {
            type: "radialBar",
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              // startAngle: -150,
              // endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 0,
              left: 40,
              right: 40,
            },
          },
          // },
          // }],

        },
      },
      scoreYearSelected: (new Date()).getFullYear(),

      // insert pagination on action
      currentPage: 1,
      perPage: 1,
      rows: 100,
      refreshTop: 0,
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },

  beforeMount() {
    this.getChart()
    this.currentYearFunction()
  },

  mounted() {
    // this.getProjManagement()
    this.getDetails()
    this.getScore()
    this.getRoles()
    this.getYearScore()
    this.getProfileImage()

    if (this.roles.isUser) {
      this.getCalendar()// nonExec
    }
  },
  methods: {
    customFormatter(date) {
      this.scoreYearSelected = moment(date).format('YYYY')
      this.getYearScore(this.scoreYearSelected)
      this.getChart()
      this.refreshTop += 1
      return this.scoreYearSelected
    },
    currentYearFunction() {
      const today = new Date()
      this.currentYear = `${this.$t(today.getFullYear())}`
      // console.log('current year', this.currentYear)
    },
    getProfileImage() { // exec || hod || nonExec
      this.$axios
        .get(`${this.$baseUrl}/profile_picture/getProfilePicture`)
        .then(response => {
          const val = response.data.data.file

          if (val === null) {
            this.profileImage = null
          } else {
            this.profileImage = val.url
          }
        })
    },
    // deleteTitle() {
    //   this.title = null
    //   this.getProjManagement()
    // },
    // createProjectActivity() {
    //   this.getProjManagement()
    // },
    // updateProjectTitle(val) { // exec || hod
    //   this.title = val
    // },

    // getProjManagement() { // exec || hod
    //   const data = new FormData()
    //   this.$axios
    //     .get(`${this.$baseUrl}/executive/getProjectManagement`, data)
    //     .then(response => {
    //       if (response.data.data.length === 0) {
    //         this.title = null
    //         this.title_id = null
    //       } else {
    //         this.title = response.data.data[0].title
    //         this.title_id = response.data.data[0].id
    //       }
    //       // this.projectList = response.data.data[0].employee
    //       // params.forEach(k => {
    //       //   console.log(k)
    //       // });
    //       // this.year_score = response.data.data
    //     })
    // },
    getYearScore(scoreYearSelected) { // exec || hod || nonExec
      this.$axios
        .get(
          `${this.$baseUrl}/dashboard/filter_final_score?year=${scoreYearSelected}`,
        )
        .then(response => {
          // console.log("yearrrr", scoreYearSelected)
          this.scoreValue = response.data.data
          if (this.scoreValue !== null){
            this.adminApprove = this.scoreValue.shr_approve
            this.score = this.scoreValue.score
            this.finalEva = this.scoreValue.final_evaluation
            this.perfGrade = this.scoreValue.performance_grade
            // console.log("scoreeee", this.scoreValue.score)
          }
          // console.log('res', this.scoreValue)

          // if (response.data.success === false) {
          //   this.noScore = response.data.message
          //   console.log("messageee", this.noScore)
          // }
          // console.log("hereee", response.data.data)
          // params.forEach(k => {
          //   console.log(k)
          // });
          // this.year_score = response.data.data
        })
    },
    getRoles() { // exec || hod
      const data = new FormData()
      this.$axios
        .get(`${this.$baseUrl}/roles/getAllRoles`, data)
        .then(response => {
          this.role = response.data.data[2].name
        })
    },
    getDetails() { // exec || hod || nonExec
      const params = new FormData()
      params.append("user_id", this.id)

      this.$axios
        .get(`${this.$baseUrl}/users/current_user`, params)
        .then(response => {
          // console.log(response.data.data)
          if (response.data.success === true) {
            const profileData = response.data.data
            this.subsidiary = profileData.subsidiary
            this.approved_at = profileData.performance.approved_at
            this.name = profileData.name
            this.email = profileData.email
            this.position = profileData.employee.position
            this.mobile_number = profileData.employee.mobile_number
            this.employee_number = profileData.employee.employee_number
            this.department_id = profileData.department
            this.supervisor_id = profileData.supervisor

            this.hire_date = profileData.employee.hire_date
            // this.hire_date = profileData.employee.hire_date.split("-").reverse().join("-")
          } else {
            // this.$errorConfirmation()
            this.errorConfirmation("danger")
          }
        })
    },

    getChart() { // exec || hod || nonExec
      const params = new URLSearchParams()
      params.append("year", this.scoreYearSelected)
      this.$axios
        .get(`${this.$baseUrl}/dashboard/action_needed`, { params })
        .then(response => {
          // console.log('res chart', response)
          if (response.data.success === true) {
            if (this.goalOverviewRadialBar.series.length !== 0){
              this.goalOverviewRadialBar.series = []
            }
            this.goalOverviewRadialBar.series.push(
              response.data.data.percentage,
            )
            // kena push sbb data number tapi series array
            const tempList = response.data.data.action_needed_list
            const status = response.data.data.internal_industry_status
            // this.items = response.data.data.action_needed_list
            this.percentage = response.data.data.percentage
            if (this.items.length !== 0){
              this.items = []
            }
            // console.log('action', tempList)
            tempList.forEach(val => {
              // console.log(val.performance_id)
              // console.log(val.name.indexOf('_') !== -1, val.name)
              // console.log(c'val', val)
              if ((val.name.indexOf('_') !== -1) === true){
                let a = val.name.split("_")
                const b = a[1].charAt(0).toUpperCase() + a[1].slice(1)

                // console.log('a', a[0])
                // console.log('b', b)
                switch (a[0]){
                  case 'p1':
                    this.items.push({ name: `${`Part 1 ${b}`}`, perfId: val.performance_id, idxTab: 0 })
                    break
                  case 'core':
                    a = a[0].charAt(0).toUpperCase() + a[0].slice(1)
                    this.items.push({ name: `${`Part 2a ${a} ${b}`}`, perfId: val.performance_id, idxTab: 1 })
                    break
                  case 'behavioural':
                    a = a[0].charAt(0).toUpperCase() + a[0].slice(1)
                    this.items.push({ name: `${`Part 2b ${a} ${b}`}`, perfId: val.performance_id, idxTab: 1 })
                    break
                  case 'internal':
                    if (status === 'enable'){
                      this.items.push({ name: `${`Part 3b Internal / Industrial Involvement`}`, perfId: val.performance_id, idxTab: 2 })
                    } else {
                      // empty
                    }
                    break
                  case 'p4':
                    this.items.push({ name: `${`Part 4 ${b}`}`, perfId: val.performance_id, idxTab: 3 })
                    break
                  default:
                    break
                }
              } else {
                switch (val.name){
                  case 'training':
                    this.items.push({ idxTab: 2, name: `${`Part 3a ${val.name.charAt(0).toUpperCase() + val.name.slice(1)}`}`, perfId: val.performance_id })
                    break
                  case 'personal':
                    this.items.push({ idxTab: 0, name: val.name.charAt(0).toUpperCase() + val.name.slice(1) })
                    break
                  case 'organization':
                    this.items.push({ idxTab: 1, name: val.name.charAt(0).toUpperCase() + val.name.slice(1) })
                    break
                  case 'education':
                    this.items.push({ idxTab: 2, name: val.name.charAt(0).toUpperCase() + val.name.slice(1) })
                    break
                  default:
                    break
                }
                // this.items.push({  })
              }
            })
          } else {
            // this.$errorConfirmation()
            this.errorConfirmation("danger")
          }
        })
    },

    getScore() { // exec || hod || nonExec
      this.$axios
        .get(`${this.$baseUrl}/myperformances/overall_performance`)
        .then(response => {
          if (response.data.success === true) {
            this.overall_total = response.data.data.overall_total
            this.overall_total_manager = response.data.data.overall_total_manager
          } else {
            // this.$errorConfirmation()
            this.errorConfirmation("danger")
          }
        })
    },
    toggleInput1() { // exec || hod
      this.disableInput1 = !this.disableInput1
      this.disableRating = !this.disableRating
    },
    getCalendar() { // nonExec
      this.$axios.get(`${this.$baseUrl}/todolist/todo_list`).then(response => {
        if (response.data.success === true) {
          // console.log(response)
          // console.log(response.data.data[0].start_time)
          // this.title = response.data.data[0].title
          // this.content = response.data.data[0].content
          // this.date2 = response.data.data[0].date
          // this.media.date2 = response.data.data[0].date
          // this.media.start_time = response.data.data[0].start_time
        } else {
          // this.$errorConfirmation()
          // this.errorConfirmation('danger')
        }
      })
    },
    DateChanged(date) { // nonExec
      const newDate = moment(date, "").format("DD-MM-YYYY")
      return newDate
    },
  },
}
</script>

  <style scoped>
  .subsidiary {
    font-size: 20px;
    font-weight: 600;
    font-family: Poppins;
    font-style: normal;
    color: #000000;
  }
  .column {
    padding-top: 1vw;
  }
  [dir="ltr"] .container,
  [dir="ltr"] .container-fluid,
  [dir="ltr"] .container-xxl,
  [dir="ltr"] .container-xl,
  [dir="ltr"] .container-lg,
  [dir="ltr"] .container-md,
  [dir="ltr"] .container-sm {
    padding-right: 1rem;
    padding-left: 0rem;
    margin-right: auto;
    margin-left: auto;
  }
  </style>

  <style >
  .vdp-datepicker * {
    box-sizing: border-box;
    font-size: small;
    text-align: center;
}

input, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: 2.142rem;
    font-size: 0.857rem;
    border: 1px solid #d8d6de;
    color: #6e6b7b;
    border-radius: 0.357rem;
}
.show-year-dashboard .vdp-datepicker__calendar {
right:  0px !important;
}
  </style>
