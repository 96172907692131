<template>
  <div>
    <!-- start calendar -->
    <div class="card p-1">
      <FullCalendar
        id="events.event.id"
        ref="calendar"
        :options="calLocale"
      >
        <template #eventContent="arg">
          <div
            :id="arg.event.id"
            class=""
            style="text-align: left"
          >
            {{ $t(arg.event.title) }}
          </div>

          <b-popover
            :target="arg.event.id"
            triggers="hover"
            placement="top"
            custom-class="popover-details"
          >
            <div class="row">
              <div class="col d-flex justify-content-between col-8">
                <div>
                  <div class="d-flex">
                    <span
                      class=""
                      style="
                      font-weight: 500;
                      color: #5e5873;
                      font-size: clamp(10px, 1vw, 18px);
                    "
                    >{{ $t(arg.event.extendedProps.titleInside) }}</span>
                  </div>

                  <span
                    class="d-flex"
                    style="
                    font-weight: 400;
                    color: #b9b9c3;
                    font-size: clamp(10px, 1vw, 12px);
                  "
                  >{{ $t(arg.event.extendedProps.agenda) }}  </span>
                </div>
              <!-- <span
                class="circle"
                style="font-size: 10px"
                :style="`background-color: ${arg.event.extendedProps.colour}`"
                >{{ arg.event.extendedProps.initialLeave }}</span
              > -->

              </div>

              <div class="col d-flex justify-content-end col-4">
                <feather-icon
                  v-b-modal.update-meeting
                  v-b-tooltip.hover.top="$t('Edit')"
                  icon="EditIcon"
                  size="25"
                  style="color: green; cursor: pointer;"
                  class=""
                  @click="getEventsByID(arg.event)"
                />
                <feather-icon
                  v-b-modal.delete-event
                  v-b-tooltip.hover.top="$t('Delete')"
                  icon="Trash2Icon"
                  size="25"
                  class="ml-1"
                  style="color: red; cursor: pointer;"
                  @click="showAlertModal('delete',arg.event.id)"
                />
              </div>
            </div>

            <hr>

            <span
              class="d-flex"
              style="
                font-weight: 400;
                color: #6e6b7b;
                font-size: clamp(10px, 1vw, 12px);
              "
            >{{ arg.event.extendedProps.location === null || arg.event.extendedProps.location === "null" || arg.event.extendedProps.location === undefined || arg.event.extendedProps.location === "undefined" ? '-' : arg.event.extendedProps.location }} </span>

            <span
              class="d-flex"
              style="
                font-weight: 400;
                color: #6e6b7b;
                font-size: clamp(10px, 1vw, 12px);
              "
            >
              {{ DateChanged(arg.event.start) }}
            </span>
            <span
              class="d-flex"
              style="
                font-weight: 400;
                color: #6e6b7b;
                font-size: clamp(10px, 1vw, 12px);
              "
            >
              {{ HourChanged(arg.event.extendedProps.startEvent) }} {{ $t('Until') }}
              {{ HourChanged(arg.event.extendedProps.endEvent) }}
            </span>
          </b-popover>
        </template>
      </FullCalendar>
    </div>
    <!-- end calendar -->
    <div class="d-flex justify-content-start">
      <b-button
        v-b-modal.create-meeting
        class="m-1 saj-button"
        variant="info"
      >
        {{ $t('Create Activity') }}
      </b-button>
      <b-modal
        id="create-meeting"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <modalC
          :event-info="{}"
          :is-update="false"
          @close="$bvModal.hide('create-meeting')"
          @create-event="CreateEventUpdate"
        />
      </b-modal>

    </div>
    <!-- edit modal -->
    <div class="d-flex justify-content-start">
      <b-modal
        id="update-meeting"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <modalC
          :event-info="evData"
          :location-info="location"
          :is-update="true"
          @close="$bvModal.hide('update-meeting')"
          @update-event="CreateEventUpdate"
        />
      </b-modal>

    </div>
    <!-- edit modal end-->
    <!-- delete events modal -->
    <b-modal
      id="delete-events"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Click submit to delete event.')"
        @cancel="hideAlertModal('events')"
        @confirm="confirmDelete('events')"
      />
    </b-modal>
    <!-- delete events modal end -->
  </div>
</template>

<script>
import {
  // BCard,
  // BCol,
  // BRow,
  // BCardBody,
  // BCardTitle,
  // BCardSubTitle,
  // BCardHeader,
  BButton,
  VBPopover,
  BPopover,
  BModal,
  VBTooltip,
  // BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from "bootstrap-vue"
// import ApexLineChart from './views/hr/dashboard/ApexLineAreaChart.vue'
// import FullCalendar from '@fullcalendar/vue'
// import flatPickr from "vue-flatpickr-component";
// import apexChatData from "./apexChartData";
import FullCalendar from "@fullcalendar/vue"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import modalC from "@/component/calendar.vue"
import Ripple from "vue-ripple-directive"
import moment from "moment"
// import axios from "axios"
import myLocale from '@fullcalendar/core/locales/ms'
import enLocale from '@fullcalendar/core/locales/en-gb'
import SAJToast from '@/component/saj-toastification.vue'
import AlertModal from "@/pages/alert-modal.vue"

export default {
  components: {
    // BCard,
    // BCol,
    // BRow,
    // BCardBody,
    // BCardTitle,
    // BCardSubTitle,
    // BCardHeader,
    FullCalendar,
    BPopover,
    BButton,
    modalC,
    BModal,
    AlertModal,
    // interactionPlugin,
    // flatPickr,
    // BCardHeader,
    // BCardBody,
    // BCardSubTitle,
    // BCardTitle,
  },

  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  eventDidMount() {
    // {description: "Lecture", department: "BioChemistry"}
  },

  data() {
    return {
      location: null,
      evData: {},
      allEventsGlobal: [],
      title: "",
      // 4 cards
      //   pendingClaim: [],
      //   pendingLeave: "0",
      //   pendingOdd: [],
      //   todayAtt: "0",
      //   totalStaff: [],
      //   workFromHome: "2",
      isModalCTVisible: false,
      isModalCVisible: false,
      // calendar
      isClick: false,
      currentLocale: myLocale,
      deleteId: null,

      // calOptions: {
      //   // dayMaxEventRows: true,
      //   plugins: [dayGridPlugin, interactionPlugin],
      //   // dayMaxEventRows: 4,
      //   initialView: "dayGridMonth",
      //   headerToolbar: {
      //     left: "title",
      //     center: "",
      //     right: "prev,today,next",
      //   },
      //   // buttonText: {
      //   //   today: "Today",
      //   // },
      //   events: [
      //     // more events here
      //   ],
      //   eventDisplay: "block",
      //   eventRender() {
      //   },
      //   // locale: ((this.$i18n.locale === 'ma') ? myLocale : enLocale),

      //   dayMaxEvents: false,

      // },
      // eventActive: [
      //   {
      //     id: "",
      //     title: "",
      //     agenda: "",
      //     // location: "",
      //     date: "",
      //     start: "",
      //     end: "",
      //   },
      // ],

      addingMode: true,
      indexToUpdate: "",

    }
  },
  computed: {
    calLocale(){
      return {
        // dayMaxEventRows: true,
        plugins: [dayGridPlugin, interactionPlugin],
        // dayMaxEventRows: 4,
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "title",
          center: "",
          right: "prev,today,next",
        },
        // buttonText: {
        //   today: "Today",
        // },
        // events: [
        //   // more events here
        // ],
        events: this.allEventsGlobal,
        eventDisplay: "block",
        eventRender() {
        },
        locale: ((this.$i18n.locale === 'ma') ? myLocale : enLocale),

        dayMaxEvents: false,
        addingMode: true,
        indexToUpdate: "",

      }
    },
  },

  created() {
    this.getevents()
  },

  mounted() {
  },

  methods: {
    showAlertModal(type, id) {
      switch (type){
        case 'events':
          this.$bvModal.show('delete-events')
          this.deleteId = id
          break
        default:
          this.$bvModal.show('delete-events')
          this.deleteId = id
      }
    },
    hideAlertModal(type) {
      switch (type){
        case 'events':
          this.$bvModal.hide('delete-events')
          break
        default:
          this.$bvModal.hide('delete-events')
      }
    },
    confirmDelete(type){
      switch (type){
        case 'events':
          this.deleteEvent(this.deleteId)
          break
        default:
          this.deleteEvent(this.deleteId)
      }
    },
    deleteEvent(deleteMeeting){
      const data = new FormData()
      data.append('id', deleteMeeting)

      this.$axios.post(`${this.$baseUrl}/calender/delete_calender_event`, data).then(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t(`Successfully deleted`)}.`,
              icon: 'Trash2Icon',
              variant: 'success',
              titleColor: '#000',
              iconBg: '#e80202',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.hideAlertModal()
        this.getevents()
      })
    },
    CreateEventUpdate(){
      this.getevents()
    },
    DateChanged(date) {
      const newDay = moment(date, "").format("D-MM-yyyy")
      return newDay
    },

    HourChanged(time24) {
      let ts = time24
      const H = +ts.substr(0, 2)
      let h = (H % 12) || 12
      h = (h < 10) ? (`0${h}`) : h // leading 0 at the left for 1 digit hours
      const ampm = H < 12 ? " AM" : " PM"
      ts = h + ts.substr(2, 3) + ampm
      return ts
    },

    getevents() {
      // reload
      this.calLocale.events = []
      const allEvents = []
      this.$axios
        .get(`${this.$baseUrl}/calender/getAll_calender_event`)
        .then(response => {
          response.data.data.forEach(val => {
            allEvents.push({
              id: val.id,
              title: val.title,
              titleInside: val.title,
              startEvent: val.start_event,
              endEvent: val.end_event,
              agenda: val.agenda,
              date: `${val.date}T00:00:01`,
              location: val.location !== "undefined" || val.location !== null || val.location !== undefined ? val.location : '-',

            })
            // console.log("response", val)
          })
        })

      this.allEventsGlobal = allEvents
    },

    getEventsByID(getEvent){
      this.$axios.get(`${this.$baseUrl}/calender/getID_calender_event?id=${getEvent.id}`).then(response => {
        const event = response.data
        if (event.success){
          // console.log("get all meeting data", this.evData)
          this.evData = response.data.data
          this.location = this.evData.location === "undefined" || this.evData.location === undefined || this.evData.location === null || this.evData.location === "null" ? '-' : this.evData.location
          this.$bvModal.show('update-meeting')
        }
      })
    },
    showCalendarModal() {
      this.isModalCVisible = true
    },
    closeCModal() {
      this.isModalCVisible = false
    },
  },

  //   },
}
</script>

<style>
.fc .fc-button-primary {
  background-color: var(--fc-button-bg-color, #fff);
  border-color: #ff000f;
  border: 1px solid #ff000f;
  color: #ff000f;
}

.fc .fc-button-primary:hover {
  background-color: var(--fc-button-bg-color, #fff);
  border-color: #ff000f;
  border: 1px solid #ff000f;
  color: #ff000f;
}
.fc .fc-button-primary:focus {
  background-color: var(--fc-button-bg-color, #fff) !important;
  border-color: #ff000f !important;
  border: 1px solid #ff000f !important;
  color: #ff000f !important;
}
.fc .fc-button-primary:disabled {
  background: rgba(255, 144, 4, 0.12);
  background-color: var(--fc-button-bg-color, #fff);
  border-color: #ff000f;
  border-color: var(--fc-button-border-color, #ff000f);
  /* border: 2px solid #FE9004; */
  color: #ff000f;
}
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #efefef;
  color: #000;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #babfc7;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.popop {
  position: absolute;
  right: 50%;
  bottom: 50%;
  left: 30%;
  top: 30%;
  margin: auto;
  height: 10%;
  border: 1px solid #babfc7;
  padding: 10px;
  z-index: 1;
  width: 30%;
  background-color: #efefef;
}

.popover-details {
  max-width: 700px !important;
  width: 300px !important;
  border: 1px solid #babfc7;
  background-color: #efefef;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.circle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.fc .fc-toolbar-title {
  font-size: 17px;
  font-weight: 500;
  color: #5e5873;
}
</style>
