<template>

  <div
    class="p-1 modalScroll"
    style="width: auto; height: 75vh;"
  >
    <div
      class="mb-1 d-flex justify-content-center saj-header"
    >
      {{ $t('Activity') }}
    </div>
    <!-- {{ eventInfo }} -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-form-group
          class="saj-title"
          :label="$t('Title')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Title')"
            rules="required"
          >
            <b-form-input
              v-model="eventInfo.title"
              :placeholder="$t('Enter title')"
              class="saj-subtitle"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="saj-title"
          :label="$t('Agenda')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Agenda') "
            rules="required"
          >
            <b-form-textarea
              v-model="eventInfo.agenda"
              :placeholder="$t('Enter agenda')"
              class="saj-subtitle"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="saj-title"
          :label="$t('Location')"
        >
          <b-form-input
            v-model="location"
            :placeholder="$t('Enter location')"
            class="saj-subtitle"
          />
        </b-form-group>

        <b-form-group
          class="saj-title"
          :label="$t('Date')"
        >
          <flat-pickr
            v-if="isUpdate"
            v-model="eventInfo.date"
            :placeholder="$t('Choose date')"
            class="form-control"
            :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
          />
          <flat-pickr
            v-else
            v-model="item.tempDate"
            :placeholder="$t('Choose date')"
            class="form-control"
            :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat: 'd-m-Y'}"
            @input="setDate()"
          />
        </b-form-group>
        <validation-provider
          v-if="isUpdate === false"
          #default="{ errors }"
          :name="$t('Date') "
          rules="required"
          class="saj-title"
        >
          <input
            v-model="item.date"
            type="hidden"
          >

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <b-form-group
          class="saj-title"
          :label="$t('Start Time')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Start Time')"
            rules="required"
          >
            <flat-pickr
              v-model="eventInfo.start_event"
              class="form-control"
              :placeholder="$t('Enter start time')"
              :config="config"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="saj-title"
          :label="$t('End Time')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('End Time')"
            rules="required"
          >
            <flat-pickr
              v-model="eventInfo.end_event"
              class="form-control"
              :placeholder="$t('Enter date time')"
              :config="config"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <div
      class="d-flex justify-content-center"
      style="
              <!-- background:pink; -->
            "
    >
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="
                  color: white;
                  background: #ff0000;
                "
        @click="close()"
      >
        {{ $t('Back') }}
      </button>
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        style="

                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
        @click="isUpdate ? updateEvent() : createEvent()"
      >
        {{ $t('Save') }}
      </button>
    </div>
  </div>

</template>
<script>
import {
  //   BButton,
  //   BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  // BOverlay,
  // BInputGroup,
  // BFormGroupAppend,
  //   BCard,
  // BFormTimepicker,
  //   BRow,
  //   BCol,
  //   BCalendar,
  //   BCardText,
} from "bootstrap-vue"
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import flatPickr from "vue-flatpickr-component"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    flatPickr,
    // BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BInputGroup,
    // BFormGroupAppend,
    // BCard,
    // BFormTimepicker,
    // BCardCode,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
    // BOverlay,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    eventInfo: {
      type: Object,
      default: () => {},
    },
    locationInfo: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      location: null,
      item: [
      ],
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        allowInput: true,
        time_24hr: false,
        hourIncrement: 1,
        altFormat: "h.i K",
        altInput: true,
      },
      required,
    }
  },
  mounted() {
    setTimeout(() => {
      this.location = this.locationInfo
    }, 300)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setDate(){
      this.item.date = this.item.tempDate
    },
    createEvent() {
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('title', this.eventInfo.title)
          data.append('agenda', this.eventInfo.agenda)
          data.append('location', this.location)
          data.append('date', this.item.date)
          data.append('start_event', this.eventInfo.start_event)
          data.append('end_event', this.eventInfo.end_event)

          this.$axios.post(`${this.$baseUrl}/calender/create_calender_event`, data).then(() => {
            setTimeout(() => {
              this.$emit('close')
              this.$emit('create-event')
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('Meeting successfully saved')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }, 1000)
          })
        }
      })
    },
    updateEvent() {
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('id', this.eventInfo.id)
          data.append('title', this.eventInfo.title)
          data.append('agenda', this.eventInfo.agenda)
          data.append('location', this.location === "" ? '-' : this.location)
          data.append('date', this.eventInfo.date)
          data.append('start_event', this.eventInfo.start_event)
          data.append('end_event', this.eventInfo.end_event)
          // data.forEach(element => console.log("append", element))
          this.$axios.post(`${this.$baseUrl}/calender/update_calender_event`, data).then(() => {
            setTimeout(() => {
              this.$emit('close')
              this.$emit('update-event')
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t('Meeting successfully updated')}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }, 1000)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
[dir] .b-form-btn-label-control.form-control > .dropdown-menu {
  padding-bottom: 3rem;
}
[dir=ltr] .mr-auto, [dir=ltr] .mx-auto {
    margin-right: auto !important;
    padding-bottom: 2rem;
}

</style>
