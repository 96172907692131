<template>
  <div
    class=""
  >
    <div v-if="items.length === 0">
      <span> {{ $t("Top Performers not available") }}</span>
    </div>
    <div
      v-for="(item, index) in items"
      v-else
      :key="index"
      class="employee-task d-flex justify-content-between align-items-center mb-2 saj-text"
    >
      <div
        class="pr-2"
        style="
        width:300px;
        "
      >
        <h6
          class="mb-1"
        >
          {{ item.user_name }}
        </h6>
      </div>
      <div class="d-flex align-items-center mr-5 pr-2 pb-2">
        <b-badge
          class=" text-center"
          style="
                      background:#E5F8EE ;
                      position:absolute;
                      border-radius: 6px;
                      height:35px;
                      width: 80px;
                    "
        >
          <h3
            class="font-weight-bolder saj-text"
            style=""
          >
            {{ item.scores }}
          </h3>
        </b-badge>
      </div>
    </div>
  </div>
</template>
<script>
import {
//   BButton,
//   BModal,
//   BForm,
//   BFormInput,
//   BFormGroup,
//   BFormTextarea,
  //   BCard,
//   BFormDatepicker,
//   BRow,
//   BCol,
//   BCalendar,
//   BCardText,
  // BCardBody,
  BBadge,
  // BMediaBody,
  // BMedia,
} from "bootstrap-vue"
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    // BButton,
    // BModal,
    // BForm,
    // BFormInput,
    // BFormGroup,
    // BFormTextarea,
    // BCard,
    // BFormDatepicker,
    // BCardCode,
    // BCardBody,
    BBadge,
    // BMediaBody,
    // BMedia,
    // vSelect,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
  },
  props: {
    year: {
      type: String,
      default: null,
    },
  },

  data(){
    return {
      items: [],

    }
  },
  mounted(){
    this.getTopPerformer()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getTopPerformer() {
      // const params = new FormData()
      // params.append("user_id", this.id)

      const params = new URLSearchParams()
      params.append("year", this.year)
      params.append('status', 'active')

      this.$axios.get(`${this.$baseUrl}/dashboard/top_performer`, { params }).then(response => {
        if (response.data.data !== undefined) {
          this.items = response.data.data
        }
        this.$emit('reload')
        // console.log('tp', response)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.eventtext{
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.4px;
}
</style>
